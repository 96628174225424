import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import footerStyle from "./scss/footer.module.scss"
import fb from "../images/icons/001-facebook.svg"
import ig from "../images/icons/002-instagram.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      image: file(relativePath: { eq: "logoWhite.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer className={footerStyle.footer}>
      <Img
        fixed={data.image.childImageSharp.fixed}
        alt="Beech and greene logo"
      />
      <a href="tel:07876 340 703">Call 07876 340 703</a>

      <div className={footerStyle.sociallink}>
        <a href="https://www.facebook.com/beechandgreene" target="_blank">
          <img src={fb} alt="facebook icon link" />
        </a>
        <a
          href="https://www.instagram.com/beechandgreene.co.uk/"
          target="_blank"
        >
          <img src={ig} alt="instagram icon link" />
        </a>
      </div>

      <span>&copy; Beech &amp; Greene 2020</span>

      <div className={footerStyle.netfrontier}>
        <a href="https://www.netfrontier.co.uk/" target="_blank">
          Built By Net Frontier
        </a>
      </div>
    </footer>
  )
}

export default Footer
