import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import headerStyle from "./scss/header.module.scss"
import fb from "../images/icons/001-facebook-grey.svg"
import ig from "../images/icons/002-instagram-grey.svg"

const Header = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div>
      <header className={headerStyle.header}>
        <div className={headerStyle.sociallink}>
          <a href="https://www.facebook.com/beechandgreene" target="_blank">
            <img src={fb} alt="facebook icon link" />
          </a>
          <a
            href="https://www.instagram.com/beechandgreene.co.uk/"
            target="_blank"
          >
            <img src={ig} alt="instagram icon link" />
          </a>
        </div>
        <div className={headerStyle.logo}>
          <Img
            fixed={data.images.childImageSharp.fixed}
            alt="Beech and greene logo"
          />
          <h1>Beech &amp; Greene</h1>
        </div>
      </header>
      <nav className={headerStyle.nav}>
        <ul>
          <li>
            <Link
              to="/"
              activeStyle={{
                color: "#2b4965",
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              activeStyle={{
                color: "#2b4965",
              }}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              activeStyle={{
                color: "#2b4965",
              }}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Header
