import React from "react"
import layoutStyle from "./scss/layout.module.scss"
import Header from "./Header"
import Footer from "./Footer"
const Layout = props => {
  return (
    <div className={layoutStyle.countainer}>
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
